<template>
  <v-container>
    <v-row
        justify="start"
        align="center"
        class="mt-4"
    >
      <div
          style="width: 150px"
          class="mr-10"
      >
        <v-select
            label="Customer"
            :items="tripbitsCustomerList"
            v-model="currentTripbitsCustomer"
            item-text="label"
            item-value="id"
            return-object
            @change="changeCustomer"
        />
      </div>

      <div
          class="mr-10"
      >
        <v-btn
            :color="!isFiltersChanged ? 'info' : 'warning' "
            class="ma-2"
            @click="getData"
            :outlined="!isFiltersChanged"
        >
          {{ isFiltersChanged ? 'Update' : 'Updated ' }}
        </v-btn>
      </div>
      <div
          class="mr-10"
      >
      <GreyBarWithTitleAndValue
          title="Destinations"
          :value="tripbitsCustomerInfo.destinations_number"
          is-with-borders
      />
      </div>

      <div
          class="mr-10"
      >
      <GreyBarWithTitleAndValue
          title="Available Reels"
          :value="tripbitsCustomerInfo.reels_number"
          is-with-borders
      />
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          outlined
          colored-border
          type="info"
        >
        Welcome to the Video Asset Library! Scroll through to view and
        download captivating footage of your destinations to incorporate
        into your marketing efforts. For a closer look, simply click on any
        thumbnail to preview the video. To download, click the arrow on each
        video and it will save to your device.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          :cols="6"
      >
        <TablePaginatedToggleButton
            label="Your Destinations"
            :main-list="geotagsComputed"
            :is-global-percent="false"
            :override-headers="destinationsOverrideHeaders"
            @setActiveItem="setActiveGeotagItem"
            is-mark-active-item
            is-with-search
            sort-field="videos"
        />
      </v-col>
      <v-col
        :cols="6"
      >
        <TablePaginatedToggleButton
            label="Points of interests"
            :main-list="poisComputed"
            :override-headers="poisOverrideHeaders"
            :is-global-percent="false"
            is-with-search
            sort-field="videos"
            is-empty-array-always-on-bottom
        >
          <template v-slot:item.videos="{ item }">
            <v-row
                no-gutters
                style="flex-wrap: nowrap"
            >
              <v-col
                  v-for="(video, i) in item.videos"
                  :key="i"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <v-card
                      v-bind="attrs"
                      v-on="on"
                      class="video-card mx-1"
                      @click="showVideo(video.vimeo_uri)"
                      :max-width="100"
                  >
                    <v-img v-if="!!video.preview_url"
                           :src="video.preview_url"
                           :max-width="100"
                    />
                      <v-card-actions
                          class="card-actions"
                      >
                        <v-btn
                            color="orange"
                            small
                            icon
                            @click.stop="downloadVideo(video.vimeo_uri)"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{on, attr}">
                              <v-icon
                                v-bind="attr"
                                v-on="on"
                              >
                                mdi-download
                              </v-icon>
                            </template>
                            download video
                          </v-tooltip>
                        </v-btn>
                      </v-card-actions>
                  </v-card>
                  </template>
                  created at
                  {{ new Date(video.created_at).toISOString().slice(0, 10).replace('T', ' ') }}
                </v-tooltip>
              </v-col>
            </v-row>

          </template>
        </TablePaginatedToggleButton>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
            outlined
            colored-border
            type="warning"
        >
          Destination Reels are provided by our travel ambassadors who film for us in
          cities around the world. To ensure that all of your destinations have coverage,
          we do source some content from other platforms. These supplemental videos will
          not be available in the Video Assets Library.
        </v-alert>
      </v-col>
    </v-row>
    <DialogVideoPlayer
        :show.sync="dialogs.videoShowDialog.isOpened"
        :src.sync="dialogs.videoShowDialog.data.src"
    />
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
// import axios from 'axios';
import TablePaginatedToggleButton from '../components/TablePaginatedToggleButton.vue';
import DialogVideoPlayer from '../components/dialogs/DialogVideoPlayer.vue';
import manageDialogsMixin from '../mixins/manageDialogsMixin';
import GreyBarWithTitleAndValue from '../components/microcomponents/GreyBarWithTitleAndValue.vue';

export default {
  name: 'VideoAssets',
  components: {
    GreyBarWithTitleAndValue,
    DialogVideoPlayer,
    TablePaginatedToggleButton,
  },

  mixins: [
    manageDialogsMixin,
  ],

  data: () => ({
    tripbitsCustomerList: [],

    currentTripbitsCustomer: {
      id: null,
      name: '',
    },

    isFiltersChanged: true,

    tripbitsCustomerGeotags: [],

    tripbitsCustomerInfo: {},

    selectedGeotagIndex: 0,

    destinationsOverrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
      {
        text: '', value: 'videos', tabs: ['every'],
      },
    ],

    poisOverrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
      {
        text: 'Videos', value: 'videos', tabs: ['default'],
      },
    ],

    dialogs: {
      videoShowDialog: {
        isOpened: false,
        data: {
          src: '',
        },
        defaults: {
          src: '',
        },
      },
    },
  }),

  async created() {
    await this.initPage();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    async initPage() {
      await this.fetchTripbitsCustomerList();

      this.currentTripbitsCustomer = this.tripbitsCustomerList.length
        ? this.tripbitsCustomerList[0]
        : {
          id: null,
          name: '',
        };

      if (this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])) {
        await this.getData();
      }
    },

    async fetchTripbitsCustomerList() {
      const response = await this.$requestHelpers
        .get('api/admin/customer/getUserTripbitsCustomerList');

      if (!response.data.length) {
        await this.$router.push({ name: 'root' });
      }

      this.tripbitsCustomerList = response.data.map(
        (el) => ({
          id: el.id,
          name: el.name,
          label: el.name,
        }),
      );
    },

    changeFilters(flag = true) {
      this.isFiltersChanged = flag;
    },

    async changeCustomer() {
      this.changeFilters();
    },

    setActiveGeotagItem(item) {
      this.selectedGeotagIndex = this.geotagsComputed
      // eslint-disable-next-line max-len
        .findIndex((el) => (item.id != null && el.id === item.id) || (item.label != null && el.label === item.label));
    },

    getData() {
      this.$requestHelpers
        .post(
          'api/admin/customer/getUserCustomerGeotags',
          {
            tripbits_customer_id: this.currentTripbitsCustomer.id,
          },
        )
        .then((response) => {
          this.changeFilters(false);

          this.tripbitsCustomerGeotags = response.data.geotag_poi_video ?? [];
          this.tripbitsCustomerInfo = response.data.customer_info_short ?? {};
        });
    },

    showVideo(vimeoUri) {
      this.$requestHelpers
        .post('api/admin/analytics/getTheHighestResolutionVimeoUri',
          {
            vimeo_uri: vimeoUri,
          }).then((response) => {
          this.openDialog('videoShowDialog', { src: response.data.result.uri });
        });
    },

    downloadVideo(vimeoUri) {
      this.$requestHelpers
        .post(
          'api/admin/analytics/getVimeoVideoDownloadLink',
          {
            vimeo_uri: vimeoUri,
          },
        ).then((response) => {
          this.$store.dispatch('overlay/showOverlay', true);

          const fileLink = document.createElement('a');

          fileLink.href = response.data.result.download_url;
          document.body.appendChild(fileLink);

          fileLink.onclick = () => {
            setTimeout(() => {
              this.$store.dispatch('overlay/showOverlay', false);
            }, 1500);
          };

          fileLink.click();
        });
    },
  },

  computed: {
    ...mapGetters({
      userId: 'auth/userId',
    }),

    geotagsComputed() {
      return this.tripbitsCustomerGeotags
        .filter((el) => el.pois.length)
        .map((el) => ({
          id: el.id,
          label: el.name,
          videos: el.pois.reduce((carry, val) => carry + val.videos.length, 0),
          pois: el.pois ?? [],
        }));
    },

    poisComputed() {
      if (!this.geotagsComputed.length) {
        return [];
      }

      return this.geotagsComputed[this.selectedGeotagIndex]?.pois.map(
        (el) => ({ id: el.id, label: el.name, videos: el.videos }),
      );
    },
  },
};
</script>
<style scoped>
.card-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}
</style>
