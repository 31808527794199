<template>
  <v-dialog
    max-width="300px"
    v-model="showDialog"
  >
    <video
      controls
      playsinline
      autoplay
      loop
      :src='srcDialog'
    />
  </v-dialog>

</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },

  name: 'DialogVideoPlayer',
  data: () => ({
    showDialog: false,
    srcDialog: '',
  }),
  methods: {

  },

  watch: {
    show(val) {
      this.showDialog = val;
    },
    showDialog(val) {
      this.srcDialog = val ? this.src : '';
      this.$emit('update:src', this.srcDialog);
      this.$emit('update:show', val);
    },
  },
};
</script>

<style scoped>

</style>
