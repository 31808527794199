import { render, staticRenderFns } from "./DialogVideoPlayer.vue?vue&type=template&id=5bcb97e7&scoped=true&"
import script from "./DialogVideoPlayer.vue?vue&type=script&lang=js&"
export * from "./DialogVideoPlayer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bcb97e7",
  null
  
)

export default component.exports