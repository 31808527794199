import { getObjectValueByPath } from 'vuetify/lib/util/helpers';

const manageDialogsMixin = {
  data: () => ({
    dialogs: {
      defaultDialog: {
        isOpened: false,
        data: {
          text: '',
        },
        defaults: {
          text: '',
        },
      },
    },
  }),

  methods: {
    getDialogObject(dialogObjectName = 'defaultDialog') {
      return typeof this.dialogs[dialogObjectName] === 'undefined'
        ? {}
        : this.dialogs[dialogObjectName];
    },

    openDialog(dialogObjectName, data = null) {
      const dialog = this.getDialogObject(dialogObjectName);
      dialog.data = {
        ...dialog.defaults, ...data,
      };

      dialog.isOpened = true;
    },

    closeDialog(dialogObjectName) {
      const dialog = this.getDialogObject(dialogObjectName);
      dialog.isOpened = false;

      this.resetDialog(dialogObjectName);
    },

    resetDialog(dialogObjectName) {
      const dialog = this.getDialogObject(dialogObjectName);

      dialog.data = { ...dialog.defaults };
    },

    getDialogData(dialogObjectName, key) {
      const dialog = this.getDialogObject(dialogObjectName);

      return getObjectValueByPath(dialog.data, key);
    },
  },
};

export default manageDialogsMixin;
