<template>
  <div
      class="pa-4 text-center fill-height d-flex flex-column justify-space-between"
      :class="{ borders: isWithBorders, grey: !isWithBorders, 'lighten-2': !isWithBorders }"
  >
    <div class="text-center grey--text text--darken-2 text-h6">
      {{ title }}
    </div>
    <div
      class="text-center grey--text text--darken-4 text-h4"
    >
      {{ value || '' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreyBarWithTitleAndValue',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    isWithBorders: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .borders {
    border: #E0E0E0 solid 2px;
    background-color: white;
  }
</style>
